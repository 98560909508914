<template>
    <div>
        <div>
            <video style="display:none" controls="" id="audio">
                <source id="audio-source" src="" type="audio/mpeg">
            </video>
        </div>

        <div slot="no-body" class="tabs-container">
            <vs-popup :title="popupTitle" :active.sync="employeePopupActive">
                <ag-grid-vue style="height: 55vh"
                             ref="agGridTable"
                             :components="components"
                             :gridOptions="gridOptions"
                             class="ag-theme-material w-100 my-4 ag-grid-table"
                             :columnDefs="columnDefs"
                             :defaultColDef="defaultColDef"
                             :rowData="workShiftData"
                             :animateRows="true"
                             :pagination="true"
                             :paginationPageSize="20"
                             :onGridReady="onGridReady"
                             :suppressPaginationPanel="true">
                </ag-grid-vue>
                <vs-pagination
                    :total="totalPages"
                    :max="maxPageNumbers"
                    v-model="currentPage"/>
            </vs-popup>
            <!-- Content Row -->
            <div class="vx-row mt-3">
                <div class="vx-col" style="flex : 3">
                    <vx-card>
                        <div class="d-theme-border-grey-light border-r-0 border-b-0 border-l-0">
                            <p class="">Chưa chấm công</p>
                            <p style="cursor:pointer" @click="showNotCheckedInEmployee" class=" text-3xl font-semibold">
                                {{ totalEmployee - checkedIn }}</p>
                        </div>
                    </vx-card>
                </div>
                <div class="vx-col" style="flex : 3">
                    <vx-card>
                        <div class="d-theme-border-grey-light border-r-0 border-b-0 border-l-0">
                            <p class="">Đi muộn</p>
                            <p style="cursor:pointer" @click="showLateEmployee" class="text-3xl font-semibold">
                                {{ totalLate }}</p>
                        </div>
                    </vx-card>
                </div>
            </div>
            <div class="vx-row mt-6" style="height: 100vh; display: flex; flex-direction: row">
                <div class="vx-col" style="flex: 2">
                    <vx-card>
                        <img id="stream-area" style="width: 100%" v-bind:src="streamPath"/>
                    </vx-card>
                </div>
                <div style="overflow:hidden; height: 100%; flex: 1" class="vx-col md:w-1/3 w-full">
                    <div class="vx-card">
                        <div class="vx-card__collapsible-content vs-con-loading__container" style="padding: 1.5rem;">
                            <div id="face-area" class="vx-card__body"
                                 style="padding: 0rem; overflow: auto; height: 55vh;">
                                <div style="background-color: #dddddd;" v-for="(message, index) in messages">
                                    <div style="display:table; margin-top: 5px; padding: 7px 7px 7px 7px;">
                                        <img style="display:table-cell; border-radius: 50%; width: 80px; height: 80px"
                                             :src="messages[messages.length - index - 1].imageUrl"/>
                                        <div
                                            style="display:table-cell; vertical-align:middle; padding-left: 30px; style">
                                            <div class="mb-1" style="color: black">
                                                {{ messages[messages.length - index - 1].fullName }}
                                            </div>
                                            <div class="mb-1"
                                                 style="background-color: #1aaa55; color: white; width: fit-content; padding-left: 5px; padding-right: 5px;">
                                                {{ messages[messages.length - index - 1].position }}
                                            </div>
                                            <div>
                                                {{ messages[messages.length - index - 1].displayCreatedAt }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import vSelect from 'vue-select';
import {AgGridVue} from "ag-grid-vue"
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue';
import authConfig from '@/../auth_config.json';
import moment from 'moment';

export default {
    components: {
        AgGridVue,
        vSelect,
        StatisticsCardLine
    },
    data() {
        return {
            audioOn: false,
            employeePopupActive: false,
            messages: [],
            appearedFaces: [],
            cameraId: null,
            streamPath: null,
            lastSearchTime: new Date(),
            totalEmployee: 0,
            checkedIn: 0,
            notCheckedIn: 0,
            totalLate: 0,
            workShiftInfo: {},
            audioQueue: [],
            searchQuery: "",
            maxPageNumbers: 7,
            // AgGrid
            gridApi: null,
            gridOptions: {},
            defaultColDef: {},
            columnDefs: [
                {
                    headerName: 'Mã nhân viên',
                    field: 'employeeCode',
                    width: 150
                },
                {
                    headerName: 'Tên nhân viên',
                    field: 'fullName',
                    width: 200
                },
                {
                    headerName: 'Thời gian đến',
                    field: 'displayCreatedAt',
                    width: 200,
                }
            ],
            components: {},
            popupTitle: null,
            workShiftData: [],
            inoutData: []
        }
    },
    created() {
        this.cameraId = 1;
        this.organizationBranchId = 1;
        this.streamPath = `${authConfig.cameraDomain}/stream/v1/video-streamer/${this.cameraId}`;
        this.loadEmployee()
        this.loadWorkShift()
        this.realTimeMessage()
        let resize = function () {
            let interval = setInterval(function () {
                let streamHeight = document.getElementById('stream-area').offsetHeight;
                let faceAreaHeight = document.getElementById('face-area').style.height;
                if (streamHeight != faceAreaHeight) document.getElementById('face-area').style.height = streamHeight + "px"
            }, 10)
            setTimeout(function () {
                clearInterval(interval)
            }, 3000)
        }
        window.onresize = function () {
            resize()
        }
        setTimeout(() => {
            var url = authConfig.crmDomain + "voice/" + "notification" + "?access_token=" + localStorage.getItem("access_token");
            document.getElementById("audio-source").src = encodeURI(url);
            document.getElementById("audio").load()
            this.$vs.dialog({
                type: 'confirm',
                title: `Xác nhận`,
                text: `Bạn có muốn bật thông báo bằng tiếng không ?`,
                accept: this.turnAudioOn,
                acceptText: "Đồng ý",
                cancelText: "Huỷ"
            })
            document.getElementById("audio").addEventListener("ended", () => {
                console.log('end');
                document.getElementById("audio-source").src = ""
            })
        })
        resize()
        let playAudio = () => {
            let interval = setInterval(() => {
                if (this.audioQueue.length == 0) {
                    return;
                } else {
                    if (!(document.getElementById("audio").duration > 0 && !document.getElementById("audio").paused)) {
                        document.getElementById("audio-source").src = ""
                    }
                }
                if (document.getElementById("audio-source").getAttribute("src")) return;
                var url = authConfig.crmDomain + "voice/" + this.audioQueue[0].employeeId + "?access_token=" + localStorage.getItem("access_token");
                document.getElementById("audio-source").src = encodeURI(url);
                try {
                    document.getElementById("audio").load()
                    setTimeout(() => {
                        document.getElementById("audio").play()
                    }, 100)
                } catch (e) {
                    document.getElementById("audio-source").src = ""
                }
                this.audioQueue.shift()
            }, 300)
        }
        playAudio()

    },
    watch: {
        '$store.state.windowWidth'(val) {
            if (val <= 1024) {
                this.maxPageNumbers = 5;
            } else {
                this.maxPageNumbers = 7;
            }
        },
    },
    computed: {
        totalPages() {
            if (this.gridApi) return this.gridApi.paginationGetTotalPages();
            else return 0
        },
        currentPage: {
            get() {
                if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
                else return 1
            },
            set(val) {
                this.gridApi.paginationGoToPage(val - 1)
            }
        },
    },
    methods: {
        turnAudioOn() {
            this.audioOn = true;
            document.getElementById("audio").play()
        },

        updateSearchQuery(val) {
            this.gridApi.setQuickFilter(val)
        },
        onGridReady() {
            if (this.$store.state.windowWidth <= 1024) {
                this.maxPageNumbers = 5;
            } else {
                this.maxPageNumbers = 7;
            }

            if (this.$store.state.windowWidth > 1024) {
                this.gridApi.sizeColumnsToFit();
            }
        },
        scrollToBottom(element, to, duration) {
            // if (duration <= 0) return;
            // var difference = to - element.scrollTop;
            // var perTick = difference / duration * 10;

            // setTimeout(() => {
            //     element.scrollTop = element.scrollTop + perTick;
            //     if (element.scrollTop === to) return;
            //     this.scrollToBottom(element, to, duration - 10);
            // }, 15);
        },
        showEmployee() {
            this.workShiftData = this.employeeData.map(item => {
                let checkInObj = this.appearedFaces.find(appearedFace => appearedFace.employeeId == item.employeeId);
                return {...item, ...checkInObj};
            })
            this.employeePopupActive = true
        },
        showCheckedEmployee() {
            this.workShiftData = [];
            let fetchedId = []
            this.appearedFaces.forEach(item => {
                if (fetchedId.includes(item.employeeId)) return;
                fetchedId.push(item.employeeId)
                this.workShiftData.push(item);
            })
            this.employeePopupActive = true
        },
        showNotCheckedInEmployee() {
            this.workShiftData = [];
            let fetchedId = []
            this.employeeData.forEach(item => {
                if (fetchedId.includes(item.employeeId)) return;
                fetchedId.push(item.employeeId)
                if (this.appearedFaces.find(appearedFace => appearedFace.employeeId == item.employeeId)) return;
                this.workShiftData.push(item);
            })
            this.employeePopupActive = true
        },
        showLateEmployee() {
            let fetchedId = [];
            this.workShiftData = [];
            if (this.workShiftInfo.workShiftSetting && this.workShiftInfo.workShiftSetting.length) {
                let checkInTime = this.workShiftInfo.workShiftSetting[0].startHour + (this.workShiftInfo.workShiftSetting[0].bufferTime || 0);
                this.appearedFaces.forEach(item => {
                    if (fetchedId.includes(item.employeeId)) return;
                    fetchedId.push(item.employeeId)
                    let appearTime = moment(item.createdAt).hour() * 60 + moment(item.createdAt).minute() + moment(item.createdAt).seconds() / 60
                    if (appearTime > checkInTime) {
                        this.workShiftData.push(item)
                    }
                })
            }
            this.employeePopupActive = true
        },
        loadEmployee() {
            this.$vs.loading();
            let path = '/employee/all';
            if (this.organizationBranchId) {
                path = '/employee/find-by-organization-branch/' + this.organizationBranchId;
            }
            this.$crm.post(path).then((response) => {
                this.totalEmployee = response.data.length
                this.employeeData = response.data;
                this.loadOldEmployee()
                this.$vs.loading.close();
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    title: 'Error',
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
        loadOldEmployee() {
            let fetchedId = [];
            let searchInOutInfo = {
                organizationBranchId: this.branchId,
                start: moment().set({hour: 0, minute: 0, second: 0, millisecond: 0}),
                end: moment().set({hour: 23, minute: 59, second: 59, millisecond: 59})
            }
            this.$processor.post('/processor/in-out-history', searchInOutInfo).then((response) => {
                let checkedIn = 0, totalLate = 0;
                this.messages.push(...response.data.reduce((arr, item) => {
                    if (fetchedId.includes(item.employeeId)) return arr;
                    fetchedId.push(item.employeeId);
                    let employee = this.employeeData.find(employee => employee.employeeId == item.employeeId)
                    item.imageUrl = authConfig.cameraDomain + "/api/v1/storage/" + item.imageUrl
                    item.displayCreatedAt = moment(item.createdAt).format("DD/MM/YYYY HH:mm:ss")
                    arr.push({...employee, ...item});
                    return arr;
                }, []));
                this.appearedFaces.push(...this.messages);
                setTimeout(() => {
                    var objDiv = document.getElementById("face-area");
                    this.scrollToBottom(objDiv, objDiv.scrollHeight, 100)
                }, 300)
                checkedIn = response.data.length
                if (this.workShiftInfo.workShiftSetting && this.workShiftInfo.workShiftSetting.length) {
                    let checkInTime = this.workShiftInfo.workShiftSetting[0].startHour + (this.workShiftInfo.workShiftSetting[0].bufferTime || 0);
                    response.data.forEach(item => {
                        let appearTime = moment(item.createdAt).hour() * 60 + moment(item.createdAt).minute() + moment(item.createdAt).second() / 60
                        if (appearTime > checkInTime) {
                            totalLate += 1;
                        }
                    })
                }
                this.checkedIn = checkedIn;
                this.totalLate = totalLate
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
        loadWorkShift() {
            this.$vs.loading();
            this.$crm.post('/work-shift/organization/find-by-organization-branch/' + this.organizationBranchId).then((response) => {
                this.workShiftInfo = response.data;
                this.$vs.loading.close();
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    title: 'Error',
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
        realTimeMessage() {
            let searchInfo = {
                cameraId: this.cameraId,
                lastSearchTime: this.lastSearchTime
            };

            setInterval(() => {
                this.$processor.post('/processor/in-out-history-by-cam', searchInfo).then((response) => {
                    searchInfo.lastSearchTime = new Date();
                    let fetchedId = [];
                    var objDiv = document.getElementById("face-area");
                    let shouldScrollBottom = false
                    if (objDiv.scrollTop + objDiv.offsetHeight == objDiv.scrollHeight) {
                        shouldScrollBottom = true;
                    }
                    response.data.forEach((item) => {
                        if (fetchedId.includes(item.employeeId)) return;
                        fetchedId.push(item.employeeId);
                        let employee = this.employeeData.find(employee => employee.employeeId == item.employeeId);
                        let lastAppear = this.appearedFaces.filter(employee => employee.employeeId == item.employeeId).pop();
                        console.log(lastAppear && moment(item.createdAt).diff(moment(lastAppear.createdAt), 's'))
                        this.appearedFaces.push({...employee, ...item});
                        if (lastAppear && moment(item.createdAt).diff(moment(lastAppear.createdAt), 's') < 60 * 5) {
                            return
                        }
                        item.imageUrl = authConfig.cameraDomain + "/api/v1/storage/" + item.imageUrl
                        item.displayCreatedAt = moment(item.createdAt).format("DD/MM/YYYY HH:mm:ss")

                        this.messages.push({...employee, ...item});
                        if (this.audioOn) this.audioQueue.push(employee)

                    }, [])

                    if (shouldScrollBottom) {
                        setTimeout(() => {
                            this.scrollToBottom(objDiv, objDiv.scrollHeight, 100)
                        }, 100)
                    }
                    this.$vs.loading.close();
                }).catch((err) => {
                    console.log(err)
                });
                let searchInOutInfo = {
                    organizationBranchId: this.branchId,
                    start: moment().set({hour: 0, minute: 0, second: 0, millisecond: 0}),
                    end: moment().set({hour: 23, minute: 59, second: 59, millisecond: 59})
                }
                this.$processor.post('/processor/in-out-history', searchInOutInfo).then((response) => {
                    let checkedIn = 0, totalLate = 0;
                    checkedIn = response.data.length
                    if (this.workShiftInfo.workShiftSetting && this.workShiftInfo.workShiftSetting.length) {
                        let checkInTime = this.workShiftInfo.workShiftSetting[0].startHour + (this.workShiftInfo.workShiftSetting[0].bufferTime || 0);
                        response.data.forEach(item => {
                            let appearTime = moment(item.createdAt).hour() * 60 + moment(item.createdAt).minute() + moment(item.createdAt).second() / 60
                            if (appearTime > checkInTime) {
                                totalLate += 1;
                            }
                        })
                    }
                    this.checkedIn = checkedIn;
                    this.totalLate = totalLate
                }).catch((err) => {
                    this.$vs.loading.close();
                    // return this.$vs.notify({
                    //   text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    //   iconPack: 'feather',
                    //   icon: 'icon-alert-circle',
                    //   color: 'danger'
                    // });
                });
            }, 3000)
        }
    },
    mounted() {
        this.gridApi = this.gridOptions.api;
    },
}
</script>

<style scoped>

</style>
